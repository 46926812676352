import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(app)/about": [5,[2]],
		"/(app)/bio-submission": [~6,[2]],
		"/(app)/calendar": [7,[2]],
		"/calendar/plan": [105],
		"/(app)/calendar/[year]": [8,[2]],
		"/(app)/donate": [9,[2]],
		"/(app)/employee-covid-policies": [10,[2]],
		"/(app)/entries": [11,[2]],
		"/(app)/gifts": [12,[2]],
		"/(app)/industry-professionals": [13,[2]],
		"/(app)/jobs": [14,[2]],
		"/(app)/jobs/2020-03-25-season-cancelled": [15,[2]],
		"/(app)/jobs/2021-02-11-you-are-subscribed": [16,[2]],
		"/(app)/jobs/2022-01-03-actors-for-2022": [17,[2]],
		"/(app)/jobs/2022-01-03-musicians-for-2022": [18,[2]],
		"/(app)/jobs/2022-03-19-local-actors-for-2022": [19,[2]],
		"/(app)/jobs/2022-03-22-seeking-a-drummer-for-2022": [20,[2]],
		"/(app)/jobs/2022-03-24-local-actors-for-2022-update": [21,[2]],
		"/(app)/jobs/2022-04-16-box-office-help-wanted": [22,[2]],
		"/(app)/jobs/2022-09-16-producing-artistic-director": [23,[2]],
		"/(app)/jobs/2023-01-26-pro-actors-for-2023": [24,[2]],
		"/(app)/jobs/2023-03-04-local-actor-auditions-2023": [25,[2]],
		"/(app)/jobs/2024-01-15-local-actor-auditions-2024": [26,[2]],
		"/(app)/jobs/history": [27,[2]],
		"/(app)/media": [~28],
		"/(app)/news": [29,[2,3]],
		"/(app)/news/2015-01-08-annual-raffle": [30,[2,3]],
		"/(app)/news/2015-03-17-the-big-winners": [31,[2,3]],
		"/(app)/news/2015-05-19-don-fox-in-the-spotlight": [32,[2,3]],
		"/(app)/news/2015-06-04-spotlight-lori-berg": [33,[2,3]],
		"/(app)/news/2015-06-11-spotlight-amanda-wansa-morgan": [34,[2,3]],
		"/(app)/news/2015-06-18-spotlight-samantha-matthews": [35,[2,3]],
		"/(app)/news/2015-08-05-thank-you-for-2015-season": [36,[2,3]],
		"/(app)/news/2015-11-01-the-2016-season-and-game-and-parks": [37,[2,3]],
		"/(app)/news/2015-11-17-season-fully-announced": [38,[2,3]],
		"/(app)/news/2016-01-26-annual-raffle": [39,[2,3]],
		"/(app)/news/2016-03-29-fun-at-the-annual-raffle": [40,[2,3]],
		"/(app)/news/2016-05-11-bmr-opening": [41,[2,3]],
		"/(app)/news/2016-05-31-opening-beauty-and-the-beast": [42,[2,3]],
		"/(app)/news/2016-06-04-opening-addams-family": [43,[2,3]],
		"/(app)/news/2016-06-10-opening-legally-blonde": [44,[2,3]],
		"/(app)/news/2016-06-25-opening-cabaret": [45,[2,3]],
		"/(app)/news/2016-07-04-opening-spamalot": [46,[2,3]],
		"/(app)/news/2016-11-05-announcing-2017-season": [47,[2,3]],
		"/(app)/news/2017-05-14-50th-anniversary-special-events": [48,[2,3]],
		"/(app)/news/2017-05-29-opening-shrek": [49,[2,3]],
		"/(app)/news/2017-06-02-special-event-frontier-strings": [50,[2,3]],
		"/(app)/news/2017-06-04-opening-mamma-mia": [51,[2,3]],
		"/(app)/news/2017-06-11-opening-crazy-for-you": [52,[2,3]],
		"/(app)/news/2017-06-25-hearing-impaired-performance": [53,[2,3]],
		"/(app)/news/2017-06-25-opening-chicago": [54,[2,3]],
		"/(app)/news/2017-07-02-opening-young-frankenstein": [55,[2,3]],
		"/(app)/news/2017-07-14-the-2017-season-is-open": [56,[2,3]],
		"/(app)/news/2017-08-16-thank-you": [57,[2,3]],
		"/(app)/news/2017-10-05-season-announced": [58,[2,3]],
		"/(app)/news/2018-03-06-annual-raffle": [59,[2,3]],
		"/(app)/news/2018-03-18-fun-at-the-annual-raffle": [60,[2,3]],
		"/(app)/news/2018-05-14-pizza-hut-fundraiser": [61,[2,3]],
		"/(app)/news/2018-05-15-new-toll-free-number": [62,[2,3]],
		"/(app)/news/2018-08-30-thank-you-2018": [63,[2,3]],
		"/(app)/news/2018-11-01-announcing-2019-season": [64,[2,3]],
		"/(app)/news/2019-02-15-lyle": [65,[2,3]],
		"/(app)/news/2019-03-06-annual-raffle": [66,[2,3]],
		"/(app)/news/2019-04-01-fun-at-the-annual-raffle": [67,[2,3]],
		"/(app)/news/2019-09-22-announcing-2020-season": [68,[2,3]],
		"/(app)/news/2019-10-20-announcing-partnership-with-homestake-opera": [69,[2,3]],
		"/(app)/news/2020-02-22-annual-raffle": [70,[2,3]],
		"/(app)/news/2020-03-16-annual-raffle-cancelled": [71,[2,3]],
		"/(app)/news/2020-03-24-annual-raffle-winners": [72,[2,3]],
		"/(app)/news/2020-03-25-season-cancelled": [73,[2,3]],
		"/(app)/news/2020-06-02-blackout-tuesday": [74,[2,3]],
		"/(app)/news/2020-06-13-post-playhouse-memories": [75,[2,3]],
		"/(app)/news/2021-03-15-cancelling-2021-season": [76,[2,3]],
		"/(app)/news/2021-07-05-returning-in-2022": [77,[2,3]],
		"/(app)/news/2021-09-30-announcing-2022-season": [78,[2,3]],
		"/(app)/news/2022-03-14-raffle-reschedule": [79,[2,3]],
		"/(app)/news/2022-04-15-annual-raffle-winners": [80,[2,3]],
		"/(app)/news/2022-08-14-tom-says-goodbye": [81,[2,3]],
		"/(app)/news/2022-12-01-announcing-2023-season": [82,[2,3]],
		"/(app)/news/2023-01-13-new-artistic-director-announced": [83,[2,3]],
		"/(app)/news/2023-07-20-cabaret-announcement": [84,[2,3]],
		"/(app)/news/2023-10-10-new-artistic-team": [85,[2,3]],
		"/(app)/news/2023-10-17-announcing-2024-season": [86,[2,3]],
		"/(app)/news/2024-02-06-annual-raffle": [87,[2,3]],
		"/(app)/news/2024-07-29-cabaret-and-season-announcement": [88,[2,3]],
		"/(app)/news/2024-08-08-early-season-end": [89,[2,3]],
		"/(app)/plan-your-visit": [90,[2]],
		"/(app)/policies": [91,[2]],
		"/(app)/productions": [92,[2]],
		"/(app)/productions/[year]": [93,[2]],
		"/(app)/program-ad-info": [94,[2]],
		"/(app)/program-bios": [95,[2]],
		"/(app)/ratings": [96,[2]],
		"/(app)/sentry-example": [97,[2]],
		"/(app)/slideshow/2022": [98],
		"/(app)/slideshow/2023": [99],
		"/(app)/slideshow/2024": [100],
		"/(app)/support": [101,[2]],
		"/(app)/ticket-info": [102,[2]],
		"/(app)/who": [103,[2]],
		"/(app)/who/[year]": [104,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';